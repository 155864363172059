import React, { useState, useEffect } from 'react';
import { PythonOutlined } from '@ant-design/icons';

import { courses_list } from '../../lib/properties/CoursesListProps';
import { api_urls } from '../../lib/urls';
import Api from '../../lib/request-service';
import { Link } from 'react-router-dom';

const CoursesList = () => {
  const [courses, setCourses] = useState(courses_list);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await Api.get(api_urls.COURSES.courses, true);
        if (response?.statusCode === 200 && response?.data) {
          setCourses(response.data);
        } else {
          console.log('Unable to fetch courses from API.');
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchCourses();
  }, []);

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col items-center justify-center px-8 py-4">
      <h1 className="py-4 text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-12 animate-pulse font-sans">
        Programming Courses
      </h1>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-8">
          {courses.map((course, index) => (
            <Link to="/course" key={course.id} className="hover:no-underline">
            <div
              key={course.id}
              className="bg-gray-800 rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300 ease-in-out flex flex-col min-w-[300px]"
            >
              <course.icon className="text-purple-500 text-5xl mb-4" />
              <h2 className="text-2xl font-bold text-white mb-2 text-center">{course.name}</h2>
              {course.description.map((line, index) => (
                <p key={index} className="text-gray-300 items-left">
                  • {line}
                </p>
              ))}
            </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoursesList;
