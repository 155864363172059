
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import Navbar from './components/Navbar/Navbar';
import Home from './components/HomePage/Home';
import Course from './components/Courses/Courses';
import StudentDashboard from './components/StudentDashboard/Dashboard';
import PaymentInfo from './components/StudentDashboard/PaymentInfo';
import RegistrationInfo from './components/StudentDashboard/RegistrationInfo';
import Results from './components/StudentDashboard/Results';
import AboutUsMain from './components/About/AboutUs';

function App() {
  return (
    <Router>
      <div className="bg-gray-900">
        <Navbar />
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Signup />} />
            <Route path='/course' element={<Course/>} />
            <Route path='/StudentDashboard' element={<StudentDashboard/>}/>
            <Route path='/PaymentInfo' element={<PaymentInfo/>}/>
            <Route path='/RegistrationInfo' element={<RegistrationInfo/>}/>
            <Route path='/Results' element={<Results/>}/>
            <Route path='/AboutUs' element={<AboutUsMain/>}/>
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
