import { Link } from 'react-router-dom';
import Homepic from "../../assets/homepage_1.png"
import { YoutubeOutlined } from '@ant-design/icons';
function Header(props) {
  const isLoggedIn = localStorage.getItem("token");

  return (
    <div className="bg-gray-900 py-12">
      <div className="container mx-auto px-4 md:px-0 flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 lg:w-2/5 md:pr-8 md:pl-0 lg:pl-24">
          <div className="text-4xl md:text-5xl font-extrabold text-teal-500">
            <span className="font-bold text-amber-500">Learning Code Online</span>
            <br />
            <span className="font-bold text-indigo-300">is now much easier</span>
          </div>
          <div className="mt-6 md:mt-8 text-lg md:text-xl leading-7 md:leading-10 text-indigo-300">
            CodOps Academy is an online platform <br /> for learning Computer Coding for all ages
          </div>
          <div className="pt-10 md:mt-10 flex flex-col md:flex-row md:items-center gap-4 md:gap-5">
            <Link to={isLoggedIn ? "/course" : "/register"}>
              <div className="bg-amber-500 text-center text-gray-900 font-semibold px-6 py-3 md:px-8 md:py-4 rounded-full bg-amber-500 text-gray-900 hover:bg-gray-800 hover:text-amber-500 transition-colors duration-300">
                {isLoggedIn ? "Courses" : "Join Now"}
              </div>
            </Link>
            <div className="flex items-center text-gray-100 text-center md:text-left">
              <button>
                <YoutubeOutlined className="w-8 md:w-12 aspect-square mr-2" style={{ fontSize: '50px', '@media (minWidth: 768px)': { fontSize: '10px' } }} />
              </button>
              <span className="text-sm md:text-base ml-4 sm:ml-0">Watch how it works</span>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 lg:w-3/5 mt-8 md:mt-0">
          <img src={Homepic} alt="pic" className="w-full max-w-lg mx-auto" />
        </div>
      </div>
    </div>
  );
}

export default Header;