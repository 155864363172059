import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { DollarOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { AppstoreOutlined } from '@ant-design/icons';
import { FileSearchOutlined } from '@ant-design/icons';
import ProfilePic from "../../assets/profilepic.png";
import session from "../../lib/session";
import {api_urls} from "../../lib/urls"; 
import Api from "../../lib/request-service";

const currentDate = new Date();
const options = { month: 'long', day: '2-digit', year: 'numeric' };
const formattedDate = currentDate.toLocaleDateString('en-US', options);


function StudentDashboard(props) {

  const [firstName, setFirstName] = useState("");
  const [courses, setCourses] = useState([]);
  const [currentTime, setCurrentTime] = useState("");



const isWithinTimeRange = (current_time, start_time, end_time) => {
  try{
    // Function to parse time strings into Date objects
    const parseTime = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, seconds, 0);
      return date;
    };

    // Parse input times
    const currentTime = parseTime(current_time);
    const startTime = parseTime(start_time);
    const endTime = parseTime(end_time);

    // Calculate the time difference in milliseconds
    const differenceInMinutes = (time1, time2) => {
      return Math.abs(time1 - time2) / (1000 * 60);
    };

    // Check if current time is within the start and end time range
    const isWithinRange = currentTime >= startTime && currentTime <= endTime;

    // Check if the difference with start time or end time is within 10 minutes
    const isCloseToStartOrEnd = differenceInMinutes(currentTime, startTime) <= 10 || differenceInMinutes(currentTime, endTime) <= 10;

    return isWithinRange || isCloseToStartOrEnd;
  }
  catch {
    return false;
  }
  };

  useEffect(() => {
    const fetchEnrollments = async () => {
      try {
        const response = await Api.get(api_urls.ENROLLMENTS.enrollment_list, true);
        console.log(response)
        if (response?.data?.statusCode === 200 && response?.data?.data?.data) {
          const transformedCourses = response.data.data.data.map(course => ({
            id: course.course_id,
            name: course.course__name,
            link: course.course__meet_link,
            start_time: course.course__class_start_time,
            end_time: course.course__class_end_time,
          }));
          setCourses(transformedCourses);
          setCurrentTime(response.data.data.current_time)
        } else {
          console.log("Unable to fetch courses from API.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchEnrollments();

  }, []);
  

  useEffect(() => {
    const user_info = session.getUser();
    if (user_info){
      setFirstName(user_info.first_name);
    }
  }, []);

  return (
    <div className="flex flex-col py-12 pr-14 pl-5 h-auto w-auto bg-gray-900 shadow-lg max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
        <div className="place-items-start flex flex-col w-1/4 max-md:ml-0 max-md:w-full ml-10 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl">
          <div className="flex flex-col grow px-6 text-cyan-100 pt-8 pb-12 mt-1.5 ml-auto w-full text-xs text-center whitespace-nowrap rounded-2xl max-md:px-5 max-md:mt-10">
            <img
              loading="lazy"
              src={ProfilePic}
              className="mx-auto rounded-3xl aspect-[0.99] w-[200px]"
              alt="Profile Picture"
            />
            {/* <div className="flex gap-2 mt-20 ml-6">
              <DollarOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/PaymentInfo">
                <span className="grow">Payment Info</span>
              </Link>
            </div> */}
  
            <div className="flex gap-2 mt-10 ml-6">
              <EditOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/RegistrationInfo">
                <span className="grow">Registered Courses</span>
              </Link>
            </div>
            {/* <div className="flex gap-2 mt-10 ml-6">
              <AppstoreOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/course">
                <span className="grow">Courses</span>
              </Link>
            </div> */}
            {/* <div className="flex gap-2 mt-10 ml-6">
              <FileSearchOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/Results">
                <span className="grow">Results</span>
              </Link>
            </div> */}
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[83%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col mt-1.5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col items-start py-12 h-fit pr-20 pl-10 text-xs text-center bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-lg max-md:px-5 max-md:max-w-full">
              <div>{formattedDate}</div>
              <div className="mt-16 text-xl font-semibold whitespace-nowrap max-md:mt-10">
                Welcome back, {firstName}!
              </div>
              <div className="mt-1.5 mb-12 whitespace-nowrap max-md:mb-10">
                Always stay updated in your student portal
              </div>
            </div>
            <div className="mt-10 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                <div className="flex flex-col w-[68%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col mt-3.5 max-md:mt-10 max-md:max-w-full">
                    <div className="self-start ml-3.5 text-sm font-semibold text-center text-amber-500 whitespace-nowrap max-md:ml-2.5">
                      Enrolled Courses
                    </div>
                    <div className="mt-8 max-md:mt-10 max-md:max-w-full overflow-x-auto flex gap-5 md:gap-0">
                      {courses && courses.map((course, index) => (
                        <div className="flex gap-5 mb-10 max-md:flex-col max-md:gap-5 max-md:" key={index}>
                          <div className="justify-between flex flex-col ml-5 w-auto max-md:ml-0 max-md:w-full">
                            <div className="flex grow gap-5 justify-between items-start self-stretch pt-5 pr-14 pb-12 pl-5 w-full text-xs font-semibold rounded-2xl border-amber-500 border-solid shadow-lg bg-violet-500 bg-opacity-30 border-[2.605px] max-md:px-5 max-md:mt-4">
                              <div className="flex flex-col flex-1">
                                <div className="leading-6 text-amber-500 text-xl">
                                  {course.name}
                                </div>
                                <div className="text-gray-100 mt-6 text-center">
                                  08:00 PM - Saturday & Sunday
                                </div>
                                {/* {isWithinTimeRange(currentTime, course.start_time, course.end_time) &&
                                  <button className="flex justify-center mt-8">
                                    <a href={course.link} target="__blank" className="px-4 py-1 text-gray-900 whitespace-nowrap bg-amber-500 rounded-2xl w-28 text-center">
                                      Join class
                                    </a>
                                  </button>} */}
                                  {isWithinTimeRange(currentTime, course.start_time, course.end_time) ?
                                      <button className="flex justify-center mt-8">
                                      <a href={course.link} target="__blank" className="px-4 py-1 text-gray-900 whitespace-nowrap bg-amber-500 rounded-2xl w-28 text-center">
                                      Join class
                                      </a>
                                      </button>
                                      :
                                      <button className="flex justify-center mt-8 opacity-50 cursor-not-allowed" disabled>
                                      <a className="px-4 py-1 text-gray-900 whitespace-nowrap bg-amber-500 rounded-2xl w-28 text-center">
                                      Join class
                                      </a>
                                      </button>
                                  }
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
  
                <div className="announcement-container flex flex-col ml-5 h-min w-[32%] max-md:ml-0 max-md:w-full">
                  <div className="announcement-header flex flex-col grow text-xl text-center font-semibold leading-4 text-amber-500 max-md:mt-10">
                    <div className="self-center">Announcements</div>
                  </div>
                  <div className="announcement-content flex flex-col shrink-0 mt-8 bg-gray-900 max-h-[20rem] overflow-y-auto p-4 rounded-lg">
                    {/* <p className="announcement-item text-white text-lg font-bold mb-2">Exciting News!</p>
                    <p className="announcement-item text-white">Stay tuned for updates.</p> */}
                    <div className="announcement-item mt-4">
                      <p className="text-white text-lg font-bold mb-2">New Announcement:</p>
                      <p className="announcement-item text-white">All classes will start on given time.</p>
                    </div>
                    <div className="announcement-item mt-4">
                      <p className="text-white text-lg font-bold mb-2">New Announcement:</p>
                      <p className="announcement-item text-white">If teacher doesn't show up within 10 minutes, the class is cancelled.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDashboard;