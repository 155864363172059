import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Picture from "../../assets/authentication.png";
import { notification } from 'antd';
import {api_urls} from "../../lib/urls";
import session from "../../lib/session";
import Api from "../../lib/request-service";
import { Helmet } from 'react-helmet';

function Login() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const user = {
      email: formData.email, 
      password: formData.password
    }
    try{
    const response = await Api.post(api_urls.AUTH.login, user, false)
    if (response?.data.statusCode === 200 && response?.data.data) {
      console.log(response?.data.data.user)
      session.setUser(
          JSON.stringify(response?.data?.data.user)
      );
      session.setToken(
          JSON.stringify(response?.data?.data?.access)
      );
      api.success({
        message: 'Login Successful',
        description: 'You have been successfully logged in.',
        placement: 'bottomLeft',
      });
      navigate("/");
        }
        else {
            console.log(response.message);
            api.error({
              message: 'Authentication Error',
              description: "Please input correct values.",
              placement: 'bottomLeft',
            });
        }}
        catch(error){
            console.error('Error logging in:', error);
            api.error({
              message: 'Authentication Error',
              description: "Please try again later!",
              placement: 'bottomLeft',
            });
        }
    setIsLoading(false);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-gray-900 pb-60 h-screen w-full flex justify-center items-center">
      <Helmet>
        <title>LogIn - CodOps Academy</title>
        <meta charSet='utf-8' />
        <meta name="description" content="CodOps Academy - Learn Computer Coding Online for All Ages" />
        <meta name="keywords" content="CodOps Academy, Coding, Computer Coding, Online Learning" />
      </Helmet>
       {contextHolder}
      <div className="bg-gradient-to-r from-gray-900 to bg-indigo-500 rounded-3xl shadow-2xl max-w-4xl w-full mx-5 md:flex relative">
        <div className="md:w-1/2 px-16 py-12">
          <h2 className="font-bold text-4xl text-amber-500 text-center md:text-left">Login</h2>
          <p className="text-white mt-2 mb-8 text-center md:text-left">Enter your account details</p>
          <form onSubmit={handleLogin} className="flex flex-col gap-4">
            <div className="relative">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder='Your Email'
              />
            </div>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder='Your Password'
              />
            </div>
            <div className="text-white text-opacity-50 text-center md:text-left">
              Forgot Password?
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-amber-500 to-blue-900 rounded-lg py-2 text-white font-semibold transition-all duration-300 hover:bg-gradient-to-r hover:from-amber-600 hover:to-blue-800 flex justify-center items-center relative"
              disabled={isLoading}
            >
              {isLoading && (
                <div className="absolute left-2 inset-y-0 flex items-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2"></div>
                </div>
              )}
              Login
            </button>
          </form>
          <div className="flex gap-5 justify-between mt-10">
            <div className="text-white text-opacity-50 text-center md:text-left">
              Don't have an account?
            </div>
            <Link to="/register" className="bg-neutral-700 rounded-lg py-2 px-4 text-amber-500 font-semibold">
              Sign up
            </Link>
          </div>
        </div>
        <div className="hidden md:block md:w-1/2">
          <div className="relative h-full">
            <img src={Picture} alt="Logo" className="absolute inset-0 w-full h-full object-cover rounded-r-3xl" />          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;