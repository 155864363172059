import React from 'react';
import { Link } from 'react-router-dom';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';

function Footer(props) {
  return (
    <div className="flex justify-center items-center px-4 py-4 bg-slate-800 sm:px-8 sm:py-6">
      <div className="flex flex-col mt-4 max-w-screen">
        {/* 1. Codops Academy and description */}
        <div className="flex gap-2 justify-between items-end text-amber-500 sm:gap-3">
          <div className="grow mt-4 text-sm font-semibold tracking-wide text-center sm:text-lg">
            CodOps Academy
          </div>
          <div className="self-stretch w-px bg-gray-500 h-[50px] sm:h-[63px]" />
          <div className="flex-auto mt-2 text-sm font-medium tracking-wide text-center sm:text-lg">
            A perfect place for learning coding online
          </div>
        </div>
        {/* 2. Subscription */}
        <div className="flex flex-col items-center mx-auto mt-8 sm:flex-row sm:flex-wrap sm:justify-center sm:pl-2 sm:mt-16">
          <div className="text-sm font-medium tracking-wide text-center text-slate-300 sm:text-lg sm:max-w-none sm:pr-5 sm:self-center">
            Subscribe to keep informed about our courses
          </div>
          <div className="flex items-center gap-2 mt-4 max-w-full sm:gap-4 sm:mt-0 sm:justify-center sm:pd-3">
            {/* Custom styled email input */}
            <div className="relative">
              <div className="absolute inset-0 bg-gray-900 opacity-80 rounded-[20px] sm:rounded-[25px]"></div>
              <input
                type="email"
                placeholder="Your Email"
                className="relative z-10 px-3 py-2 text-sm tracking-wide border border-solid border-slate-500 rounded-[20px] text-slate-500 hover:bg-slate-700 hover:text-cyan-300 bg-transparent sm:px-4 sm:py-3 sm:text-base sm:rounded-[25px]"
              />
            </div>
            <button className="px-4 py-2 text-sm font-medium text-gray-900 rounded-[40px] bg-slate-300 hover:bg-slate-500 self-center sm:px-6 sm:py-3 sm:text-lg">
              Subscribe
            </button>
          </div>
        </div>
        {/* 3. Social media icons and copyright */}
        <div className="flex flex-col gap-4 mt-4 max-w-full sm:flex-row sm:justify-around">
          <div className="flex justify-around pt-4 space-x-2 sm:pt-7 sm:space-x-5">
            <Link to="https://www.facebook.com/codopsacademy" target='__blank'>
              <FacebookOutlined
                className="text-slate-300 hover:text-slate-400"
                style={{ fontSize: '30px' }}
              />
            </Link>
            <Link to="https://www.linkedin.com/company/codops-academy" target='__blank'>
              <LinkedinOutlined
                className="text-slate-300 hover:text-slate-400"
                style={{ fontSize: '30px' }}
              />
            </Link>
            <Link to="https://www.instagram.com/codopsacademy" target='__blank'>
              <InstagramOutlined
                className="text-slate-300 hover:text-slate-400"
                style={{ fontSize: '30px' }}
              />
            </Link>
          </div>
          <div className="self-center mt-4 text-xs tracking-wide text-center text-slate-300 sm:text-base sm:mt-8">
            Copyright ©2024 CodOps, Inc. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;