import DasboardHome from "../../assets/dashoard_home.png";
import CertificatePIC from "../../assets/certificatePIc.png";
import { UnorderedListOutlined } from "@ant-design/icons";
import { RiseOutlined } from "@ant-design/icons";
import { CommentOutlined } from "@ant-design/icons";

function OurFeature(props) {
  return (
    <div className="bg-gray-900 py-16">
      <div className="container mx-auto px-4 md:px-8">
      <h1 className="py-4 text-center text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-12 animate-pulse font-sans">
        CodOps Features
      </h1>
        <p className="text-2xl md:text-3xl font-bold text-center text-gray-400 mb-16">
          Our Mission is to Deliver the Ultimate Learning Experience
        </p>

        {/* Dashboard Section */}
        <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-8 mb-16">
          <div className="md:w-1/2 lg:w-2/5 md:pr-4 md:pl-0 lg:pl-24 sm:pr-6 sm:pl-6">
            <h3 className="text-3xl md:text-4xl font-bold text-amber-500 leading-tight mb-6 text-right">
              A one-stop dashboard for seamless student navigation
            </h3>
            <div className="space-y-6 text-lg md:text-xl text-gray-300">
              <div className="flex items-start">
                <UnorderedListOutlined className="w-8 h-8 mr-3 text-cyan-400 mt-1" />
                <p>
                  A compilation of student courses, each linked to a direct
                  joining option
                </p>
              </div>
              <div className="flex items-start">
                <RiseOutlined className="w-8 h-8 mr-3 text-cyan-400 mt-1" />
                <p>
                  Students can monitor their progress with available result
                  options.
                </p>
              </div>
              <div className="flex items-start">
                <CommentOutlined className="w-8 h-8 mr-3 text-cyan-400 mt-1" />
                <p>Teacher announcements.</p>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 lg:w-3/5 md:pr-0 lg:pr-24">
            <img
              src={DasboardHome}
              alt="Homepage Dashboard"
              className="w-full max-w-lg mx-auto rounded-lg shadow-lg"
            />
          </div>
        </div>

        {/* Certification Section */}
        <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-8">
        <div className="md:w-1/2 lg:w-3/5">
          <img
            src={CertificatePIC}
            alt="Homepage certificate"
            className="w-full max-w-sm md:max-w-md lg:max-w-lg mx-auto rounded-lg shadow-lg"
          />
        </div>

          <div className="md:w-1/2 lg:w-2/5 md:pr-4 lg:pr-24 sm:pr-6 sm:pl-6">
            <h3 className="text-3xl md:text-4xl font-bold text-amber-500 leading-tight mb-6 text-left">
              Certification on successful course completion
            </h3>
            <p className="text-lg md:text-xl text-gray-300 leading-relaxed text-justify">
              Upon successful completion of courses, students will receive
              certificates, providing them with valuable credentials to enhance
              their resumes. Easily upload these certificates to your CV and
              showcase your achievements effectively
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurFeature;