// import commonFunctions from '../helpers/commonFunctions';

import session from "./session";

const HOST = process.env.REACT_APP_BASE_URL;

class Api {
    static headers(multipart = false) {
        if (multipart) {
            return {
                // 'Content-Type': `multipart/form-data`
            };
        } else {
            return {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            };
        }
    }

    static get(route, sendAuthToken = false) {
        return this.xhr(route, null, 'GET', sendAuthToken, false);
    }

    static put(route, params, sendAuthToken = false) {
        return this.xhr(route, params, 'PUT', sendAuthToken, false);
    }

    static post(route, params, sendAuthToken = false, multipart = false) {
        return this.xhr(route, params, 'POST', sendAuthToken, multipart);
    }
    static patch(route, params, sendAuthToken = false) {
        return this.xhr(route, params, 'PATCH', sendAuthToken, false);
    }

    static delete(route, params, sendAuthToken = false) {
        return this.xhr(route, params, 'DELETE', sendAuthToken, false);
    }

    static async logout() {
        localStorage.removeItem('token')
        window.location.replace('/login')
    }

    static async xhr(route, params, verb, sendAuthToken, multipart) {
        let token = session.getToken();
        const url = `${HOST}${route}`;
        let options = Object.assign({ method: verb }, params ? { body: multipart ? params : JSON.stringify(params) } : null);
        options.headers = Api.headers(multipart);
        if (token && sendAuthToken) {
            options.headers.Authorization = 'Bearer ' + token;
        }
        return fetch(url, options)
            .then((resp) => {
                let json = resp;

                if (resp.ok) {
                    return json;
                } else {
                    if (resp.status === 401) {
                        Api.logout();
                    }
                    if (resp.status === 404) {
                    }
                    return json;
                }
                return json.then((err) => {
                    if (resp.status === 401) {
                        Api.logout();
                    }
                    throw err;
                });
            })
            .then(async (res) => {
                let rs = {};
                try {
                    rs = {
                        res: res,
                        data: await res.json(),
                    };
                } catch (e) {
                    rs = {
                        res: res,
                    };
                }
                return rs;
            });
    }
}
export default Api;
