import { Link } from "react-router-dom";
import { DollarOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { AppstoreOutlined } from '@ant-design/icons';
import { FileSearchOutlined } from '@ant-design/icons';
import { RollbackOutlined  } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import ProfilePic from "../../assets/profilepic.png"
import session from "../../lib/session";
import {api_urls} from "../../lib/urls"; 
import Api from "../../lib/request-service";
const currentDate = new Date();
const options = { month: 'long', day: '2-digit', year: 'numeric' };
const formattedDate = currentDate.toLocaleDateString('en-US', options);

function convertDate(date) {
  const createdAt = new Date(date);

  const options = {
    year: "numeric",
    month: "long",
    day50: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Use 24-hour format
    timeZone: "UTC" // Ensure correct timezone
  };
  
  const formattedCreatedAt = createdAt.toLocaleString("en-US", options);
  return formattedCreatedAt;
}

function RegisteredCourses(props) {

  const [courses, setCourses] = useState([]);
  const [firstName, setFirstName] = useState("");


  useEffect(() => {
  const fetchEnrollments = async () => {
    try {
      const response = await Api.get(api_urls.ENROLLMENTS.enrollment_list, true);
      console.log(response)
      if (response?.data?.statusCode === 200 && response?.data?.data?.data) {
        const transformedCourses = response.data.data.data.map(course => ({
          id: course.course_id,
          name: course.course__name,
          link: course.course__meet_link,
          created_at: course.created_at,
          price: course.course__price,
          description: course.course__date_description
        }));
        setCourses(transformedCourses);
      } else {
        console.log("Unable to fetch courses from API.");
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  fetchEnrollments();
  }, []);
  
  useEffect(() => {
    const user_info = session.getUser();
    if (user_info){
      setFirstName(user_info.first_name);
    }
  }, []);


  return (
    <div className="flex flex-col py-12 pr-14 pl-5 h-auto w-auto bg-gray-900 shadow-lg max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
        <div className="place-items-start flex flex-col w-1/4 max-md:ml-0 max-md:w-full ml-10 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl">
          <div className="flex flex-col grow px-6 text-cyan-100 pt-8 pb-12 mt-1.5 ml-auto w-full text-xs text-center whitespace-nowrap rounded-2xl max-md:px-5 max-md:mt-10">
            <img
              loading="lazy"
              src={ProfilePic}
              className="mx-auto rounded-3xl aspect-[0.99] w-[200px]"
              alt="Profile Picture"
            />
            {/* <div className="flex gap-2 mt-20 ml-6">
              <DollarOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/PaymentInfo">
                <span className="grow">Payment Info</span>
              </Link>
            </div> */}
            <div className="flex gap-2 mt-10 ml-6">
              <EditOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/RegistrationInfo">
                <span className="grow">Registered Courses</span>
              </Link>
            </div>
            {/* <div className="flex gap-2 mt-10 ml-6">
              <AppstoreOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/course">
                <span className="grow">Courses</span>
              </Link>
            </div> */}
            {/* <div className="flex gap-2 mt-10 ml-6">
              <FileSearchOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/Results">
                <span className="grow">Results</span>
              </Link>
            </div> */}
            <div className="flex gap-2 mt-10 ml-6">
              <RollbackOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
              <Link to="/StudentDashboard">
                <span className="grow">Back</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[83%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col mt-1.5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col items-start py-12 h-fit pr-20 pl-10 text-xs text-center bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-lg max-md:px-5 max-md:max-w-full">
              <div>{formattedDate}</div>
              <div className="mt-16 text-xl font-semibold whitespace-nowrap max-md:mt-10">
                Welcome back, {firstName}!
              </div>
              <div className="mt-1.5 mb-12 whitespace-nowrap max-md:mb-10">
                Always stay updated in your student portal
              </div>
            </div>
            <div className="bg-gray-900 mt-10 ml-2 max-h-[400px] max-md:w-full overflow-y-auto">
              <div className="flex flex-col ml-2 mr-4 max-md:w-full">
                <div className="self-start ml-3.5 text-sm font-semibold text-center text-amber-500 whitespace-nowrap max-md:ml-2.5">
                  Registered Courses
                </div>
                <div className="mt-10 max-md:max-w-full flex flex-col gap-5">
                  {courses && courses.map((course, index) => (
                    <div key={course.id} className="flex flex-col ml-5 w-auto max-md:w-full">
                      <div className="flex grow gap-5 justify-between items-start self-stretch pt-5 pr-14 pb-12 pl-5 w-full text-xs font-semibold rounded-2xl border-amber-500 border-solid shadow-lg bg-violet-500 bg-opacity-30 border-[2.605px] max-md:px-5 max-md:mt-4">
                        <div className="flex flex-col flex-1">
                          <div className="leading-6 text-amber-500 text-xl">
                            Course Name: {course.name}
                          </div>
                          <div className="text-gray-100 mt-6 text-center">
                            Registration Time: {convertDate(course.created_at)}
                          </div>
                          <div className="text-gray-100 mt-6 text-center">
                            Price: Rs.{course.price}
                          </div>
                          <div className="text-gray-100 mt-6 text-center">
                            Instructor: Muhammad Maaz
                          </div>
                          <div className="text-gray-100 mt-6 text-center">
                            Classes Time: {course.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisteredCourses;