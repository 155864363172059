import { PythonOutlined, CodeOutlined } from '@ant-design/icons';


export const courses_list = [
    {
      id: 2,
      description: [
        "Foundations of Programming Concepts and Fundamentals",
        "Introduction to Programming with Scratch Environment",
        "Understanding Pseudo Code and its Role in Problem Solving",
        // "Exploration of Variables and Control Structures in Programming",
        "Introduction to Algorithms and Strategies for Problem-Solving",
        "Application of Programming Concepts to Solve Real-World Projects"
      ], 
      updated_at: "2024-03-24T17:10:50.300919Z",
      created_at: "2024-03-10T12:26:43.148228Z",
      icon: CodeOutlined,
      name: "Programming Fundamentals for Beginners",
      start_date: null,
      end_date: null,
      price: 20,
      teacher: 1,
      tags: [1]
    },
    {
      id: 1,
      description: [
        "Foundations of Programming and Introduction to Python",
        "Exploration of Intermediate Python Concepts and Data Structures",
        "Understanding Object-Oriented Programming (OOP) Principles in Python",
        "Application of Python for Solving Complex Algorithms",
        "Completion of a Real-World Final Project Using Python"
      ],  
      updated_at: "2024-04-14T16:37:19.400388Z",
      created_at: "2024-03-04T08:25:50.800795Z",
      icon: PythonOutlined,
      name: "Starting out with Python",
      start_date: null,
      end_date: null,
      price: 20,
      teacher: 1,
      tags: []
    }
  ];



export const courses_page_list = [
    {
      id: 2,
      description: [
        "Foundations of Programming and Introduction to Python",
        "Exploration of Intermediate Python Concepts and Data Structures",
        "Understanding Object-Oriented Programming (OOP) Principles in Python",
        "Application of Python for Solving Complex Algorithms",
        "Completion of a Real-World Final Project Using Python"
      ],    
      name: "Starting Out with Python",
      file: "python.pdf",
      price: "Rs.14,500/month",
      discounted_price: "Rs.10,000/month",
      benefits: ['Live classes', 'Online support', 'Career Counselling', 'Live Labs', 'Assessments', 'Certification'],
    },
    {
      id: 1,
      description: [
        "Foundations of Programming Concepts and Fundamentals",
        "Introduction to Programming with Scratch Environment",
        "Understanding Pseudo Code and its Role in Problem Solving",
        "Exploration of Variables and Control Structures in Programming",
        "Introduction to Algorithms and Strategies for Problem-Solving",
        "Application of Programming Concepts to Solve Real-World Projects"
      ],   
      file: "programming_fundamentals.pdf", 
      benefits: ['Live classes', 'Online support', 'Career Counselling', 'Live Labs', 'Assessments', 'Certification'],
      name: "Programming Fundamentals for Beginners",
      price: "Rs.10,000/month",
      discounted_price: "Rs.7,000/month",
    }
  ];