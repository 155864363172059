import React, {useEffect} from 'react';
import { DollarOutlined, EditOutlined, AppstoreOutlined, FileSearchOutlined, RollbackOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ProfilePic from '../../assets/profilepic.png';
import session from "../../lib/session";


const currentDate = new Date();
const options = { month: 'long', day: '2-digit', year: 'numeric' };
const formattedDate = currentDate.toLocaleDateString('en-US', options);

function StudentResults(props) {
  const [firstName, setFirstName] = React.useState('');

  useEffect(() => {
    const user_info = session.getUser();
    if (user_info){
      setFirstName(user_info.first_name);
    } 
  }, []);

  const studentData = {
    srNo: '1',
    labs: [80, 75, 90],
    assessments: [85, 92, 78],
  };

  const calculateClassAverage = () => {
    const allMarks = [...studentData.labs, ...studentData.assessments];
    const totalMarks = allMarks.reduce((sum, mark) => sum + mark, 0);
    const classAverage = totalMarks / allMarks.length;
    return classAverage.toFixed(2);
  };

  const calculateTotalMarks = () => {
    const allMarks = [...studentData.labs, ...studentData.assessments];
    const totalMarks = allMarks.reduce((sum, mark) => sum + mark, 0);
    return totalMarks;
  };

  return (
    <div className="flex flex-col py-12 pr-14 pl-5 h-screen w-full bg-gray-900 shadow-lg max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
        <div className="place-items-start flex flex-col w-1/4 max-md:ml-0 max-md:w-full ml-10 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl">
          <div className="flex flex-col grow px-6 text-cyan-100 pt-8 pb-12 mt-1.5 ml-auto w-full text-xs text-center whitespace-nowrap rounded-2xl max-md:px-5 max-md:mt-10">
            <img
              loading="lazy"
              src={ProfilePic}
              className="mx-auto rounded-3xl aspect-[0.99] w-[200px]"
              alt="Profile Picture"
            />

            {/* <div className="flex gap-2 mt-20 ml-6">
              <DollarOutlined className="self-start aspect-square w-[15px]" style={{ color: 'black' }} />
              <Link to="/PaymentInfo">
                <span className="grow">Payment Info</span>
              </Link>
            </div> */}

            <div className="flex gap-2 mt-10 ml-6">
              <EditOutlined className="self-start aspect-square w-[15px]" style={{ color: 'black' }} />
              <Link to="/RegistrationInfo">
                <span className="grow">Registered Courses</span>
              </Link>
            </div>
            {/* <div className="flex gap-2 mt-10 ml-6">
              <AppstoreOutlined className="self-start aspect-square w-[15px]" style={{ color: 'black' }} />
              <Link to="/course">
                <span className="grow">Courses</span>
              </Link>
            </div> */}
            {/* <div className="flex gap-2 mt-10 ml-6">
              <FileSearchOutlined className="self-start aspect-square w-[15px]" style={{ color: 'black' }} />
              <Link to="/Results">
                <span className="grow">Results</span>
              </Link>
            </div> */}
            <div className="flex gap-2 mt-10 ml-6">
              <RollbackOutlined className="self-start aspect-square w-[15px]" style={{ color: 'black' }} />

              <Link to="/StudentDashboard">
                <span className="grow">Back</span>
              </Link>
            </div>
          </div>
        </div>
      <div className="flex flex-col ml-5 w-[83%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col mt-1.5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col items-start py-12 h-fit pr-20 pl-10 text-xs text-center bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-lg max-md:px-5 max-md:max-w-full">
              <div>{formattedDate}</div>
              <div className="mt-16 text-xl font-semibold whitespace-nowrap max-md:mt-10">
                Welcome back, {firstName}!
              </div>
              <div className="mt-1.5 mb-12 whitespace-nowrap max-md:mb-10">
                Always stay updated in your student portal
              </div>
            </div>
          <div className="mt-10 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col mt-3.5 max-md:mt-10 max-md:max-w-full">
                <div className="self-start ml-3.5 text-sm font-semibold text-center text-amber-500 whitespace-nowrap max-md:ml-2.5">
                  Results
                </div>
                <div className="mt-8 max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-col justify-center items-center p-5 bg-gray-900 text-white rounded-lg">
                    <p className="text-lg font-semibold">Student Results</p>
                    <table className="mt-3">
                      <thead>
                        <tr>
                          <th className="px-4 py-2">Sr.no</th>
                          <th className="px-4 py-2">Labs</th>
                          <th className="px-4 py-2">Assessments</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="px-4 py-2">{studentData.srNo}</td>
                          <td className="px-4 py-2">
                            {studentData.labs.map((mark, index) => (
                              <div key={index}>{mark}</div>
                            ))}
                          </td>
                          <td className="px-4 py-2">
                            {studentData.assessments.map((mark, index) => (
                              <div key={index}>{mark}</div>
                            ))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="mt-5">
                      <span className="font-semibold">Class Average:</span>{' '}
                      {calculateClassAverage()}
                    </div>
                    <div className="mt-2">
                      <span className="font-semibold">Total Marks:</span>{' '}
                      {calculateTotalMarks()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default StudentResults;