import React, { useState } from 'react';
import { WhatsAppOutlined, FacebookOutlined, LinkedinOutlined, InstagramOutlined, RightOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import Footer from '../Footer/Footer';

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    query: '',
  });

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Submit form logic here
    console.log(formData);
    setFormData({
      fullName: '',
      email: '',
      query: '',
    });
  };

  const toggleAccordion = (index) => {
    setIsOpen(isOpen === index ? null : index);
  };

  return (
    <>
    <div className="bg-gray-900 py-16">
      <Helmet>
        <title>About - CodOps Academy</title>
        <meta charSet='utf-8' />
        <meta name="description" content="CodOps Academy - Learn Computer Coding Online for All Ages" />
        <meta name="keywords" content="CodOps Academy, Coding, Computer Coding, Online Learning" />
      </Helmet>
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          <h2 className="py-4 text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-12 animate-pulse font-sans">About CodOps Academy</h2>
          <p className="text-lg mb-6 text-gray-300 max-w-3xl text-justify">
            CodOps Academy is an online coding academy where we cater to learners of all ages. Our platform offers live classes designed to teach coding skills in various programming languages. Whether you're a child or an adult, our courses provide hands-on practice and interactive learning experiences. With expert instructors and a focus on practical application, we empower our students to master coding concepts and unleash their potential in the digital world. Join us on the journey to become proficient coders and unlock endless opportunities in the realm of technology.
          </p>
        </div>
  
        <div className="flex flex-col md:flex-row justify-center mt-12">
          <div className="md:w-1/2 mb-8 md:mb-0 px-6">
            <h3 className="text-2xl font-bold mb-4 text-amber-500">Any questions?</h3>
            <form onSubmit={handleFormSubmit} className="bg-gray-800 p-6 rounded-lg shadow-lg">
              <div className="mb-4">
                <label htmlFor="fullName" className="block text-gray-300 font-bold mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleFormChange}
                  className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-300 font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleFormChange}
                  className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="query" className="block text-gray-300 font-bold mb-2">
                  Query
                </label>
                <textarea
                  id="query"
                  name="query"
                  value={formData.query}
                  onChange={handleFormChange}
                  className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white"
                  rows="4"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-gray-800 text-amber-500 border border-amber-500 hover:bg-amber-500 hover:text-gray-900 transition-colors duration-300 py-2 px-4 rounded-lg flex items-center justify-center"
              >
                Submit <RightOutlined className="ml-2" />
              </button>
            </form>
          </div>
  
          <div className="md:w-1/2 px-6">
            <h3 className="text-2xl font-bold mb-4 text-amber-500">Frequently Asked Questions</h3>
            <div className="accordion">
              <div className="accordion-item mb-4">
                <button
                  className="accordion-header bg-gray-800 text-white p-4 w-full text-left rounded-t-lg flex justify-between items-center hover:bg-gray-700 transition duration-300"
                  onClick={() => toggleAccordion(0)}
                >
                  <span>How old should I be to enroll in a course?</span>
                  <span>{isOpen === 0 ? '-' : '+'}</span>
                </button>
                {isOpen === 0 && (
                  <div className="accordion-body bg-gray-700 text-white p-4 rounded-b-lg">
                    There is no age limit. We have different courses for different age groups of students.
                  </div>
                )}
              </div>
  
              <div className="accordion-item mb-4">
                <button
                  className="accordion-header bg-gray-800 text-white p-4 w-full text-left rounded-t-lg flex justify-between items-center hover:bg-gray-700 transition duration-300"
                  onClick={() => toggleAccordion(1)}
                >
                  <span>Is the fee for the whole course or per month?</span>
                  <span>{isOpen === 1 ? '-' : '+'}</span>
                </button>
                {isOpen === 1 && (
                  <div className="accordion-body bg-gray-700 text-white p-4 rounded-b-lg">
                    The fee is on a monthly basis.
                  </div>
                )}
              </div>
  
              <div className="accordion-item mb-4">
                <button
                  className="accordion-header bg-gray-800 text-white p-4 w-full text-left rounded-t-lg flex justify-between items-center hover:bg-gray-700 transition duration-300"
                  onClick={() => toggleAccordion(2)}
                >
                  <span>What is the eligibility criteria for certification?</span>
                  <span>{isOpen === 2 ? '-' : '+'}</span>
                </button>
                {isOpen === 2 && (
                  <div className="accordion-body bg-gray-700 text-white p-4 rounded-b-lg">
                    Students should get at least 50% marks in assessments and labs to be eligible for certification.
                  </div>
                )}
              </div>
  
              <div className="accordion-item mb-4">
                <button
                  className="accordion-header bg-gray-800 text-white p-4 w-full text-left rounded-t-lg flex justify-between items-center hover:bg-gray-700 transition duration-300"
                  onClick={() => toggleAccordion(3)}
                >
                  <span>Is this completely online?</span>
                  <span>{isOpen === 3 ? '-' : '+'}</span>
                </button>
                {isOpen === 3 && (
                  <div className="accordion-body bg-gray-700 text-white p-4 rounded-b-lg">
                    Yes, our courses are completely online.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
  
        <div className="flex flex-col md:flex-row justify-center items-center mt-12">
          <div className="flex items-center mb-4 md:mb-0 mr-4">
            <WhatsAppOutlined className="text-green-500 mr-2" />
            <span className="text-white">+92 325 9092613</span>
          </div>
          <div className="flex items-center mb-4 md:mb-0 mr-4">
            <FacebookOutlined className="text-blue-500 mr-2" />
            <a href="https://www.facebook.com/codopsacademy" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
              Facebook
            </a>
          </div>
          <div className="flex items-center mb-4 md:mb-0 mr-4">
            <LinkedinOutlined className="text-blue-400 mr-2" />
            <a href="https://www.linkedin.com/company/codops-academy" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
              LinkedIn
            </a>
          </div>
          <div className="flex items-center mb-4 md:mb-0 mr-4">
            <InstagramOutlined className="text-pink-500 mr-2" />
            <a href="https://www.instagram.com/codopsacademy" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
              Instagram
            </a>
          </div>
          </div>

     <div className="flex flex-col md:flex-row justify-center items-center mt-8">
       <div className="flex items-center mb-4 md:mb-0 mr-4">
         <span className="font-bold text-white mr-2">Location:</span>
         <span className="text-gray-300">Islamabad, Pakistan</span>
       </div>
       <div className="flex items-center mb-4 md:mb-0 mr-4">
         {/* <WhatsAppOutlined className="text-green-500 mr-2" /> */}
         <span className="text-amber-500">Free consultancy regarding course choice</span>
       </div>
     </div>
   </div>
 </div>
 <Footer />
 </>
);
};

export default AboutUs;