import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SignupImage from "../../assets/authentication.png";
import { Alert, notification } from 'antd';
import {api_urls} from "../../lib/urls";
import session from "../../lib/session";
import Api from "../../lib/request-service";
import { Helmet } from 'react-helmet';
function Signup() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phone: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const [api, contextHolder] = notification.useNotification();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const user = {
          username: formData.username,
          email: formData.email,
          password1: formData.password,
          password2: formData.confirmPassword,
          first_name: formData.firstName,
          last_name: formData.lastName,
          phone: formData.phone,
          is_student: true,
    }
    try {
    const response = await Api.post(api_urls.AUTH.register, user, false)
      if (response?.data.statusCode === 201 && response?.data.data) {
        api.success({
          message: 'Signup Successful',
          description: 'You have been successfully signed up.',
          placement: 'bottomLeft',
        });
        navigate('/login');
      }
      else {
        const err = response?.data;
        let errorMessages = '';
        Object.keys(err).forEach((key) => {
          errorMessages += `\n[${key}]: ${err[key]}`;
        });
        api.error({
          message: 'Signup Error',
          description: errorMessages.trim(),
          placement: 'bottomLeft',
        });
      }
      
    }
    catch(error){
      api.error({
        message: 'Unable to Register',
        description: "Please try again later!",
        placement: 'bottomLeft',
      });
    }
    setIsLoading(false);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const checkPasswordStrength = () => {
    const { password } = formData;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinLength = password.length >= 8;

    if (!hasMinLength) {
      return 'Password must be at least 8 characters long.';
    } else if (!hasUpperCase || !hasLowerCase || !hasNumber) {
      return 'Password must contain at least one uppercase letter, one lowercase letter, and one number.';
    } else {
      return 'Password is strong.';
    }
  };

  return (
    <div className="bg-gray-900 pb-60 min-h-screen w-full flex justify-center items-center">
      <Helmet>
        <title>Register - CodOps Academy</title>
        <meta charSet='utf-8' />
        <meta name="description" content="CodOps Academy - Learn Computer Coding Online for All Ages" />
        <meta name="keywords" content="CodOps Academy, Coding, Computer Coding, Online Learning" />
      </Helmet>
      {contextHolder}
      <div className="bg-gradient-to-r from-gray-900 to bg-indigo-500 rounded-3xl shadow-2xl max-w-4xl w-full mx-5 md:flex relative">
        <div className="hidden md:block md:w-1/2">
          <div className="relative h-full">
            <img
              src={SignupImage}
              alt="Signup"
              className="absolute inset-0 w-full h-full object-cover rounded-r-3xl"
            />
          </div>
        </div>
        <div className="md:w-1/2 px-16 py-10">
          <h2 className="font-bold text-4xl text-amber-500 text-center md:text-left">Registration</h2>
          <p className="text-white mt-2 mb-8 text-center md:text-left">Enter your account details</p>
          {alert && <Alert message={alert.message} type={alert.type} showIcon closable onClose={() => setAlert(null)} />}
          <form onSubmit={handleSignup} className="flex flex-col gap-4">
            <div className="relative">
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder="Your Username"
              />
            </div>
            <div className="relative">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder="Your Email"
              />
            </div>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder="Your Password"
              />
              <div className="text-sm text-gray-400 mt-1">{checkPasswordStrength()}</div>
            </div>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder="Confirm Password"
              />
            </div>
            <div className="relative">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder="Your First Name"
              />
            </div>
            <div className="relative">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder="Your Last Name"
              />
            </div>
            <div className="relative">
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="form-input py-2 px-3 w-full rounded-lg bg-zinc-800 text-white focus:outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
                placeholder="Your Phone"
              />
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-amber-500 to-blue-900 rounded-lg py-2 text-white font-semibold transition-all duration-300 hover:bg-gradient-to-r hover:from-amber-600 hover:to-blue-800 flex justify-center items-center relative"
              disabled={isLoading}
            >
              {isLoading && (
                <div className="absolute left-2 inset-y-0 flex items-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2"></div>
                </div>
              )}
              Register
            </button>
          </form>
          <div className="flex gap-5 justify-between mt-10">
            <div className="text-white text-opacity-50 text-center md:text-left">Already have an account?</div>
            <Link to="/login" className="bg-neutral-700 rounded-lg py-2 px-4 text-amber-500 font-semibold">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;