// import { getCookie,setCookie } from "cookies-next";

const setToken = (userToken)=>{
    return localStorage.setItem("token",userToken);
};

const removeToken = (userToken)=>{
    return localStorage.removeItem('token');
};

const getToken = ()=>{
    return  (localStorage.getItem('token') !== "undefined"|| localStorage.getItem('token') !== null)?JSON.parse(localStorage.getItem('token')):null
};

const setUser = (userData)=>{
    return localStorage.setItem("user_info",userData);
};

const getUser = () => {
    const userInfo = localStorage.getItem('user_info');
    return (userInfo !== "undefined" && userInfo !== null) ? JSON.parse(userInfo) : null;
  };
  

export default {setToken,getToken,removeToken,setUser,getUser};
