import React, { useState, useEffect } from 'react';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { courses_page_list } from '../../lib/properties/CoursesListProps';
import {api_urls} from "../../lib/urls"; 
import Api from "../../lib/request-service";
import session from "../../lib/session";
import { notification } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from '../Footer/Footer';

function Course() {
  const [courses, setCourses] = useState(courses_page_list);
  const [api, contextHolder] = notification.useNotification();
  const [userInfo, setUserInfo] = useState({
    "first_name": "",
    "last_name": "",
    "email": ""
  });
  let navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const [contactNumber, setContactNumber] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [otherSchoolName, setOtherSchoolName] = useState('');
  const [emergencyNumber, setEmergencyNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentProof, setPaymentProof] = useState(null);
  const [currentModal, setCurrentModal] = useState('');
  const [userId, setUserId] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!contactNumber) {
      alert('WhatsApp number is required!');
      return;
    }
  };
  
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await Api.get(api_urls.COURSES.courses, true);
        if (response?.data?.statusCode === 200 && response?.data?.data) {
          const transformedCourses = response.data.data.map(course => ({
            id: course.id,
            name: course.name,
            description: course.description,
            price: `${course.price} Rs./month`,
            discounted_price: `${course.discounted_price} Rs./month`,
            benefits: ['Live classes', 'Online support', 'Career Counselling', 'Live Labs', 'Assessments', 'Certification'],
            schedule: 'Fridays, Saturdays, and Sundays, 7:00 PM to 8:30 PM',
            file: course.file,
            date_description: course.date_description,
          }));
          setCourses(transformedCourses);
        } else {
          console.log("Unable to fetch courses from API.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
    const user_info = session.getUser();
    if (user_info){
      const user = {
        first_name: user_info.first_name,
        last_name: user_info.last_name,
        email: user_info.email,
      };
      setUserId(user_info.pk);
      setUserInfo(user);
    }
  }, []);
  
  const handlePrev = () => {
    if (courses.length!==0){
      setCurrentIndex((prevIndex) => (prevIndex - 1 + courses.length) % courses.length);
    }
  };

  const handleDownload = (fileName) => {
    // const fileUrl = process.env.PUBLIC_URL + fileName;
    const fileUrl = `https://codops.s3.amazonaws.com/${fileName}`
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(error => console.error('Error downloading the file: ', error));
  };
  

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % courses.length);
  };

  const handleEnrollment = () => {
    let user = session.getToken();
    if (!user){
      api.error({
        message: 'Unable to Enroll',
        description: "Please Login to continue.",
        placement: 'bottomLeft',
      });
      setTimeout(() => {
        navigate("/login");
      }, 2500);
    }
    else{
      setCurrentModal('enroll');
      setShowEnrollModal(true);
    }
  };

  const handleBack = () => {
    setCurrentModal('');
    setShowEnrollModal(false);
    setShowPaymentModal(false);
    setShowTerms(false);
  };

  const handleCheckOut = () => {
    setShowPaymentModal(true);
  }

  let currentCourse = {};
  if (courses.length > 0) {
    currentCourse = courses[currentIndex];
  }

  const handlePaymentProof = (e) => {
    console.log(
      'Payment proof uploaded:',
      e.target.files[0],
    )
    setPaymentProof(e.target.files[0]);
  };

  const handlePaymentSubmit = async (price) => {
    try {
      const formData = new FormData();
      formData.append('screenshot', paymentProof);
      formData.append('course', currentCourse.id);
      formData.append('user', userId);
      formData.append('phone_number', contactNumber);
      formData.append('amount', price.replace('$', '').replace('/month', '').replace(',',''));
  
      const enroll_response = await Api.post(api_urls.ENROLLMENTS.enrollments, {"course": currentCourse.id}, true);
      console.log(enroll_response);
  
      if (enroll_response?.data.statusCode === 200 && enroll_response?.data.data) {
        console.log('Enrollment successful:', enroll_response.data.data);
  
        const response = await Api.post(api_urls.TRANSACTIONS.transactions, formData, true, true);
        if (response?.data.statusCode === 201 && response?.data.data) {
          api.success({
            message: 'Payment sent successfully!',
            description: 'Your payment will be verified within one day.',
            placement: 'bottomLeft',
          });
        } else {
          api.error({
            message: 'Failed to complete your transaction!',
            description: "Bad Request",
            placement: 'bottomLeft',
          });
        }
  
      } else {
        api.error({
          message: 'Failed to process your enrollment.',
          description: "Please try again later!",
          placement: 'bottomLeft',
        });
      }
  
    } catch (error) {
      api.error({
        message: 'Failed to process your enrollment.',
        description: "Please try again later!",
        placement: 'bottomLeft',
      });
    }
  
    setShowPaymentModal(false);
    setContactNumber('');
    setSchoolName('');
    setOtherSchoolName('');
    setEmergencyNumber('');
    setPaymentMethod('');
    setTermsAgreed(false);
    setShowEnrollModal(false);
  };
  
  
 return (
   <>
   <Helmet>
        <title>Courses - CodOps Academy</title>
        <meta charSet='utf-8' />
        <meta name="description" content="CodOps Academy - Learn Computer Coding Online for All Ages" />
        <meta name="keywords" content="CodOps Academy, Coding, Computer Coding, Online Learning" />
    </Helmet>
     {contextHolder}
     {courses.length === 0 && <div>Loading...</div>}
     {courses.length > 0 && (
       <div className="px-24 py-16 bg-gray-900 max-md:mx-auto max-md:px-5 h-fit w-auto">
         <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
           <div className="flex flex-col min-w-[65%] max-md:ml-0 max-md:w-full">
             <div className="flex flex-col grow items-center bg-gradient-to-r from-indigo-500 px-20 py-12 mt-10 w-full text-2xl font-semibold rounded-2xl shadow-lg max-md:px-5 max-md:mt-10 max-md:max-w-full">
             <div className="mx-auto mt-3 text-5xl leading-1 text-orange-400 max-md:max-w-full max-md:text-4xl max-md:leading-1 course-name">
                {currentCourse.name}
                <br />
                <br />
              </div>
               <div className="justify-center items-center text-center py-0.5 mx-auto mt-7 max-w-full text-gray-900 whitespace-nowrap bg-amber-500 rounded-2xl w-[320px] max-md:px-5">
                 What You Get
               </div>
               <div className="self-start mt-14 text-white max-md:mt-10 max-md:max-w-full">
                 <ul>
                   {currentCourse.description.map((item, index) => (
                     <li key={index}>• {item}</li>
                   ))}
                 </ul>
               </div>
               <div className="mt-4 text-orange-400 text-sm">
              </div>

              {/* Downloadable link for course outline */}
              <div className="mt-4 text-white text-sm">
                {/* <p>For more details <a href="link_to_course_outline.pdf" download className="text-orange-400">Download Course Outline</a></p> */}
                <button 
                className="text-gray-900 bg-amber-500 border border-orange-400 px-4 py-2 rounded-md transition duration-300 ease-in-out hover:bg-white hover:text-orange-400"
                onClick={()=>handleDownload(currentCourse.file)}
              >
                Download Course Outline
              </button>

              </div>
             </div>
             <br></br>
             <p className='text-orange-400 text-sm'>
                  <span className="text-white text-sm"></span> {currentCourse.date_description ? currentCourse.date_description : "Classes are held at 8:00 PM on Fridays and Saturdays, with labs scheduled at 2:00 PM on Sundays."}
                </p>
           </div>
           <div className="flex flex-col ml-5 w-[25%] max-md:ml-0 max-md:w-full pt-10">
             <div className="flex flex-col self-stretch my-auto font-semibold max-md:mt-10">
             <div className="flex flex-col md:pl-5">
              <div className="text-orange-400 text-4xl pl-5">Price (30% Off):</div>
              <div className="flex flex-col text-4xl whitespace-nowrap mt-2 md:mt-1">
                <div className="text-white line-through pl-10">{currentCourse.price}</div>
                <div className="text-white pl-10">{currentCourse.discounted_price}</div>
              </div>
            </div>
               <div className="mx-auto mt-20 text-4xl">
              <p className="text-orange-400">Benefits:</p>
              <ul className="text-white">
                {currentCourse.benefits.map((benefit, index) => (
                  <li key={index}>• {benefit}</li>
                ))}
              </ul>
            </div>

               <div
                 className="justify-center items-center self-center px-20 py-px mt-16 mr-3.5 max-w-full text-2xl text-gray-900 whitespace-nowrap bg-amber-500 rounded-2xl w-[220px] max-md:px-5 max-md:mt-10 max-md:mr-2.5 cursor-pointer"
                 onClick={handleEnrollment}
               >
                 Enroll
               </div>
             </div>
           </div>
         </div>
         <div className="flex justify-center mt-10">
           <button
             className="px-4 py-2 mr-4 text-white bg-blue-500 rounded-full hover:bg-blue-600"
             onClick={handlePrev}
           >
             <LeftCircleOutlined style={{ fontSize: '24px' }} />
           </button>
           <button
             className="px-4 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600"
             onClick={handleNext}
           >
             <RightCircleOutlined style={{ fontSize: '24px' }} />
           </button>
         </div>

        {showEnrollModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg shadow-lg p-8 animate-fade-in-down z-50">
                <h2 className="text-2xl font-bold mb-4">
                  Enroll in Online Course
                </h2>
                <div className="mb-4">
                  <label htmlFor="studentName" className="block font-bold mb-2">
                    Student Name:
                  </label>
                  <input
                    type="text"
                    id="studentName"
                    value={userInfo.first_name + " " + userInfo.last_name}
                    disabled
                    className="w-full px-3 py-2 border rounded-md bg-gray-200 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="studentEmail" className="block font-bold mb-2">
                    Student Email:
                  </label>
                  <input
                    type="email"
                    id="studentEmail"
                    value={userInfo.email}
                    disabled
                    className="w-full px-3 py-2 border rounded-md bg-gray-200 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="contactNumber" className="block font-bold mb-2">
                    Contact Number (WhatsApp):
                  </label>
                  <input
                    type="tel"
                    id="contactNumber"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <p className="mb-2"><strong>Easypessa/Jazzcash</strong></p>
                  <p className="mb-2"><strong>Codops Account Number:</strong> 0336 5994412</p>
                  <p className="mb-2"><strong>Codops Account Holder Title:</strong> Syed Haider Ali Zaidi</p>
                  <p className='mb-2'>Please also send your payment proof to the CodOps WhatsApp number at 03259092613. Thank you!</p>
                </div>
                <div className="mb-4">
                  <label htmlFor="paymentProof" className="block font-bold mb-2">
                    Payment Proof Image:
                  </label>
                  <input
                    type="file"
                    id="paymentProof"
                    accept="image/*"
                    onChange={handlePaymentProof}
                    className="w-full px-3 py-2 border rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  </div>
                  <div className="mb-4 flex items-center">
                  <input
                    type="checkbox"
                    id="termsAgreed"
                    checked={termsAgreed}
                    onChange={(e) => setTermsAgreed(e.target.checked)}
                    className="form-checkbox text-indigo-600 focus:ring-indigo-500"
                  />
                  <label htmlFor="termsAgreed" className="ml-2">
                    I agree to the{' '}
                    <span
                      className="text-indigo-600 cursor-pointer"
                      onClick={() => setShowTerms(true)}
                    >
                      Terms and Conditions
                    </span>
                  </label>
                  </div>
                  <button
                  type="submit"
                  disabled={!termsAgreed}
                  className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={() => handlePaymentSubmit(currentCourse.discounted_price)}
                  >
                  Submit
                  </button>
                  <button
                  type="back"
                  onClick={handleBack}
                  className="w-1/4 mt-2 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                  Back
                  </button>
                  </div>
                  </div>
                  )}

                  {showTerms && (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="bg-white rounded-lg shadow-lg p-8 animate-fade-in-up">
                    <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
                    <p>
                      Here are the terms and conditions for enrolling in the online
                      course:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                      <li>You must have a stable internet connection.</li>
                      <li>You must attend all classes and complete assignments.</li>
                      <li>
                        Refunds are not available after the first week of the course.
                      </li>
                    </ul>
                    <button
                      className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setShowTerms(false)}
                    >
                      Close
                    </button>
                  </div>
                  </div>
                  )}
                </div>
)}
<Footer />
</>
);
}

export default Course;





            