import Header from './Header';
import CoursesList from './CoursesList';
import AboutUs from './AboutUs';
import Footer from '../Footer/Footer';
import OurFeature from './OurFeature';
// import Testimonial from './Testimonial';
// import Course from '../Courses';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <div>   
      <Helmet>
        <title>Home - CodOps Academy</title>
        <meta charSet='utf-8' />
        <meta name="description" content="CodOps Academy - Learn Computer Coding Online for All Ages" />
        <meta name="keywords" content="CodOps Academy, Coding, Computer Coding, Online Learning" />
      </Helmet>
      <Header />
      <CoursesList />
      <AboutUs/>
      <OurFeature/>
      {/* <Testimonial/> */}
      <Footer/>
      {/* <Course/> */}
    </div>
  )
}

export default Home;
