import React, { useState, useEffect } from "react";
import { DollarOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { AppstoreOutlined } from '@ant-design/icons';
import { FileSearchOutlined } from '@ant-design/icons';
import { RollbackOutlined  } from '@ant-design/icons';
import ProfilePic from "../../assets/profilepic.png"
import { Link } from "react-router-dom";
import session from "../../lib/session";
import {api_urls} from "../../lib/urls"; 
import Api from "../../lib/request-service";

const currentDate = new Date();
const options = { month: 'long', day: '2-digit', year: 'numeric' };
const formattedDate = currentDate.toLocaleDateString('en-US', options);

function PaymentInfo(props) {
  const [firstName, setFirstName] = useState("");
  const [paymentDetail, setPaymentDetail] = useState([]);

  useEffect(() => {
    const user_info = session.getUser();
    if (user_info){
      setFirstName(user_info.first_name);
    }
  }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await Api.get(api_urls.TRANSACTIONS.transactions, true);
        console.log(response)
        if (response?.data?.statusCode === 200 && response?.data?.data) {
          const transactions = response.data.data.map(transaction => ({
            id: transaction.id,
            last_fee_month: transaction.created_at
          }));
          setPaymentDetail(transactions);
        } else {
          console.log("Unable to fetch courses from API.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTransactions();
    }, []);
   

  // Dummy payment details data
  const paymentDetails = {
    lastFeeMonth: "March 2024",
    lastFeeAmount: 2000,
    dueAmount: 1500,
    dueDate: "2024-05-01",
    discount: 10
  };

  return (
    <div className="flex flex-col py-12 pr-14 pl-5 h-screen w-full bg-gray-900 shadow-lg max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
        <div className="place-items-start flex flex-col w-1/4 max-md:ml-0 max-md:w-full ml-10 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl">
          <div className="flex flex-col grow px-6 text-cyan-100 pt-8 pb-12 mt-1.5 ml-auto w-full text-xs text-center whitespace-nowrap rounded-2xl max-md:px-5 max-md:mt-10">
              <img
                loading="lazy"
                src={ProfilePic}
                className="mx-auto rounded-3xl aspect-[0.99] w-[200px]"
                alt="Profile Picture"
              />
            
            {/* <div className="flex gap-2 mt-20 ml-6">
                <DollarOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }}/>
                <Link to="/PaymentInfo">
                  <span className="grow">Payment Info</span>
                </Link>
            </div> */}

            <div className="flex gap-2 mt-10 ml-6">

              <EditOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }}/>
              <Link to="/RegistrationInfo">
                <span className="grow">Registered Courses</span>
              </Link>
            </div>
            {/* <div className="flex gap-2 mt-10 ml-6">
              <AppstoreOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }}/>
              <Link to="/course">
                <span className="grow">Courses</span>
              </Link>
            </div> */}
            {/* <div className="flex gap-2 mt-10 ml-6">
              <FileSearchOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />
               <Link to="/Results">
                <span className="grow">Results</span>
              </Link>
            </div> */}
            <div className="flex gap-2 mt-10 ml-6">
              <RollbackOutlined className="self-start aspect-square w-[15px]" style={{ color: "black" }} />

              <Link to="/StudentDashboard">
                <span className="grow">Back</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[83%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col mt-1.5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col items-start py-12 h-fit pr-20 pl-10 text-xs text-center bg-gradient-to-r from-cyan-500 to-blue-500 rounded-2xl shadow-lg max-md:px-5 max-md:max-w-full">
            <div>{formattedDate}</div>
            <div className="mt-16 text-xl font-semibold whitespace-nowrap max-md:mt-10">
              Welcome back, {firstName}!
            </div>
            <div className="mt-1.5 mb-12 whitespace-nowrap max-md:mb-10">
              Always stay updated in your student portal
            </div>
          </div>
           <div className="mt-10 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                <div className="flex flex-col w-[68%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col mt-3.5 max-md:mt-10 max-md:max-w-full">
                    <div className="mt-8 max-md:mt-10 max-md:max-w-full">
                      <div className="flex flex-col justify-center items-center p-5 bg-gray-900 text-white rounded-lg">
                        <p className="text-lg font-semibold mb-4 text-amber-500">Payment Details</p>
                        <table className="w-full border-collapse border border-amber-500">
                          <thead>
                            <tr>
                              <th className="px-4 py-2 border border-amber-500">Detail</th>
                              <th className="px-4 py-2 border border-amber-500">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="px-4 py-2 border border-amber-500">Last Fee Month</td>
                              <td className="px-4 py-2 border border-amber-500">{paymentDetails.lastFeeMonth}</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-2 border border-amber-500">Last Fee Amount</td>
                              <td className="px-4 py-2 border border-amber-500">Rs. {paymentDetails.lastFeeAmount}</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-2 border border-amber-500">Due Amount</td>
                              <td className="px-4 py-2 border border-amber-500">Rs. {paymentDetails.dueAmount}</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-2 border border-amber-500">Due Date</td>
                              <td className="px-4 py-2 border border-amber-500">{paymentDetails.dueDate}</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-2 border border-amber-500">Discount</td>
                              <td className="px-4 py-2 border border-amber-500">30%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;