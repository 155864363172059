export const api_urls = {
    AUTH: {
        login: "/api/auth/login",
        logout: "/api/auth/logout",
        register: "/api/auth/registration",
        user: "api/auth/user/",
        resendVerificationEmail: "api/v6/auth/resend_verification_email/",
        verifyAuthToken: "api/v6/auth/token/verify/",
        refreshToken: "api/v6/auth/token/refresh/",
        changePassword: "api/v6/auth/password/change/",
        resetPassword: "api/v6/auth/password/reset/",
        resetConfirmPassword: "api/v6/auth/password/reset/confirm/",
    },
    COURSES: {
        courses: "/courses/",
    },
    ENROLLMENTS: {
        enrollments: "/enrollment/enroll",
        enrollment_list: "/enrollment/list"
    },
    TRANSACTIONS: {
        transactions: "/transactions/",
    }
   
}

