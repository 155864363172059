import React from "react";
import AboutusPIC from "../../assets/aboutus.png";

function AboutUs(props) {
  return (
    <div className="bg-gray-900 py-16">
      <div className="container mx-auto px-4 md:px-0">
        <h2 className="text-4xl md:text-4xl font-bold text-center text-amber-500 leading-tight mb-12">
          What is <span className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-12 animate-pulse font-sans">CodOps Academy?</span>
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-16">
          <div className="md:w-1/2 lg:w-2/5 md:pr-8 md:pl-0 lg:pl-24 text-justify">
            <h6 className="text-lg md:text-xl leading-relaxed text-gray-300 mb-6">
              <span className="text-2xl font-bold text-amber-500">CodOps</span>
              <span className="text-2xl font-bold text-gray-300"> Academy</span>{" "}
              is an online coding academy where we cater to learners of all ages.
              Our platform offers live classes designed to teach coding skills
              in various programming languages.
            </h6>
            <p className="text-gray-400 mb-8">
              Whether you're a child or an adult, our courses provide hands-on
              practice and interactive learning experiences. With expert
              instructors and a focus on practical application, we empower our
              students to master coding concepts and unleash their potential in
              the digital world.
            </p>
            <p className="text-gray-400">
              Join us on the journey to become proficient coders and unlock
              endless opportunities in the realm of technology.
            </p>
          </div>
          <div className="md:w-1/2 lg:w-3/5">
            <img
              src={AboutusPIC}
              alt="CodOps Academy"
              className="w-full max-w-lg mx-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;