import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../assets/CodOps_Academy.png"
import session from "../../lib/session";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = session.getToken();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the dropdown when a link is clicked
  };

  return (
    
    <div className="relative">
      <button
        className="flex items-center justify-center w-full px-4 py-2 text-amber-500 bg-gray-900 rounded-md"
        onClick={toggleDropdown}
      >
        Menu
        <svg
          className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 w-48 bg-gray-900 rounded-md shadow-lg">
          <Link
            to="/"
            className="block px-4 py-2 text-amber-500 hover:bg-gray-800"
            onClick={handleLinkClick}
          >
            Home
          </Link>
          <Link
            to="/course"
            className="block px-4 py-2 text-amber-500 hover:bg-gray-800"
            onClick={handleLinkClick}
          >
            Courses
          </Link>
          {/* {isLoggedIn && (
            <Link
              to="/StudentDashboard"
              className="block px-4 py-2 text-amber-500 hover:bg-gray-800"
              onClick={handleLinkClick}
            >
              Dashboard
            </Link>
          )} */}
          <Link
            to="/AboutUs"
            className="block px-4 py-2 text-amber-500 hover:bg-gray-800"
            onClick={handleLinkClick}
          >
            About Us
          </Link>
        </div>
      )}
    </div>
  );
};

function Navbar(props) {
  const isLoggedIn = session.getToken();
  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate('/login');
  };

  return (
    <div className="bg-gray-900 px-5 w-auto">
      {/* <div className="bg-amber-500 text-gray-900 px-5 py-2 text-center text-sm font-semibold">
        Registrations will be opened from May 22
      </div> */}
      <div className="flex items-center justify-between max-md:flex-col">
        <div className="px-7 mt-5 w-1/4 max-md:w-full">
          <img src={Logo} alt="Logo" className="max-w-full h-auto" />
        </div>

        <div className="flex items-center ml-5 w-[30%] max-md:w-full max-md:ml-0 max-md:mt-5">
          <div className="flex gap-5 text-lg text-amber-500 max-md:hidden">
            <div><Link to="/">Home</Link></div>
            <div><Link to="/course">Courses</Link></div>
            {/* {isLoggedIn? (<div><Link to="/StudentDashboard">Dashboard</Link></div>):null} */}
            <div><Link to="/AboutUs">About Us</Link></div>
          </div>
        </div>

        <div className="flex max-md:flex-col max-md:items-center">
          <div className="flex ml-5 w-[24%] max-md:w-full max-md:ml-0 max-md:mt-5">
            {isLoggedIn ? (
              <div className="flex gap-5 text-lg whitespace-nowrap">
                <div className="flex-1 px-8 py-3 text-amber-500 bg-gray-900 shadow-xl rounded-[80px]">
                  <div className="flex flex-row">
                    <div className="justify-center px-3 py-3 font-medium text-gray-900 bg-amber-500 rounded-[80px] mx-2">
                      <button onClick={handleLogout}>Logout</button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex gap-5 text-lg whitespace-nowrap">
              <Link
                to="/login"
                className="btn-primary px-8 py-3 rounded-[80px] bg-gray-900 text-amber-500 border border-amber-500 hover:bg-amber-500 hover:text-gray-900 transition-colors duration-300 max-md:px-6 max-md:py-2"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="btn-secondary px-8 py-3 rounded-[80px] bg-amber-500 text-gray-900 hover:bg-gray-800 hover:text-amber-500 transition-colors duration-300 max-md:px-6 max-md:py-2"
              >
                Register
              </Link>
            </div>
            )}
          </div>

          <div className="dropdown-container hidden max-md:flex justify-center mt-5">
            <Dropdown />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;